.main-navigation__menu-btn {
  width: 3rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  margin-right: 2rem !important;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 100% !important;
  height: 2px !important;
  margin-bottom: 7px !important;
  background: white;
}

.main-navigation__title {
  color: #3c4991;
}

.main-navigation__title a {
  text-decoration: none;
  color: #3c4991;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

.cmsLogo {
  height: 2rem;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}