.stepperScroll {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.starColour {
    color: #8F0C0C;
}

.helpMenuBox {
    margin: 2rem auto;
}

.helpMenuTab {
    padding: 1rem;
    border-radius: 10px;
    background-image: linear-gradient(90deg, rgba(60,73,145,1) 0%, rgb(125, 139, 221) 51%, rgba(6,131,156,1) 100%);
    color: #fafafa;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: 150% auto;
    margin: 1rem 0;
}

.hh1 {
    visibility: hidden;
}

.helpMenuTabIcon {
    margin-right: 0.7rem;
    display: flex;
    align-items: cenetr;
}

.helpMenuTab:hover {
    background-position: right center; 
}

.helpMenuTabList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.instructionWithPointers {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
}

.instructionWithTitle h2 {
    font-family: 'Questrial', serif;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    text-align: center;
    margin: 2rem 0;
}

.instructionScrollBox {
    padding: 2.5rem;
    max-height: 50rem;
    overflow-y: scroll;
    background-color: #f5f7ff;
    border-radius: 20px;
}

.sliderWithVideo {
    display: flex;
    flex-direction: column;
}

.sliderWithVidTitle {
    font-family: 'Questrial', serif;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3c4991;
    font-size: 2rem;
    margin: 4rem 0;
}

.instructionScrollList {
    min-width: 100%;
}

.instructionSectionTitle {
    font-size: 1.5rem;
}

.instructionSectionTxtArea {
    margin: 3rem 0 3rem 0;
}

.instructionSectionTxtArea h3 {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
}

.instructionSectionTxt {
    margin: 1.5rem 0;
}

.instructionSectionTxt, .instructionSectionTxtArea img {
    margin: 0.5rem 0 0.75rem 0;
    max-width: 100%;
}

.alignHelpImage {
    display: flex;
    flex-direction: column;
}

.tutorialVideo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoMockUp {
    width: 90%;
    height: 15rem;
    border-radius: 20px;
    background-image: linear-gradient(-20deg, #5997c0 50%, #655699 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2rem auto;
}

.videoMockUp span {
    color: #fafafa;
}

@media (min-width: 768px) {
    .helpMenuTabList {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 auto 2rem auto;
        padding-top: 3rem;
        width: 80%;
    }

    .helpMenuTab {
        min-width: 12rem;
        margin: 0;
    }

    .videoMockUp {
        width: 25rem;
        height: 15rem;
        margin: 0 0 2rem 0;
    }

    .helpImgSm {
        width: 20%;
    }

    .helpImgMd {
        width: 50%;
    }

    .stepperScroll {
        overflow: hidden;
    }

    .instructionWithPointers {
        flex-direction: row;
        align-items: flex-start;
        padding: 1rem;
    }

    .instructionWithTitle h2 {
        text-align: left;
        margin: 0 0 2rem 0;
    }

    .instructionScrollList {
        min-width: 15rem;
        margin-right: 3rem;
    }
}