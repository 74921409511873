  .nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .nav-links li {
    margin: 1rem;
  }
  
  .nav-links a {
    border: 1px solid transparent;
    color: #eeeeee;
    text-decoration: none;
    padding: 0 3rem;
    text-align: center;
  }

  
  .navsubmenuitem a {
    text-align: center;
    margin: 0.5rem !important;
  }

  .navsubmenuitem:hover {
    background-color:rgb(46, 58, 83);
  }

  .navsubmenuitem:hover:last-child {
    background-color:rgb(46, 58, 83);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }
  
  .nav-links a:hover,
  .nav-links a:active,
  .nav-links a.active {
    color: #eeeeee;
  }
  
  .nav-links button {
    cursor: pointer;
    border: 1px solid #eeeeee;
    color: #eeeeee;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:focus {
    outline: none;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #292929;
    color: #eeeeee;
  }

  .gradient-link {
    background-image: linear-gradient(to right, #24C6DC 0%, #514A9D 51%, #24C6DC 100%);
    margin: 2rem 0 2rem 0;
    padding: 10px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: 0 0 2px #eee;
    border-radius: 10px;
    display: block;
  }

  .gradient-link a.active{
    color: white !important;
  }

  .gradient-link:hover { 
      background-position: right center; 
  }

  .navsubmenu {
    display: block;
    position: absolute;
    background: #5f699c;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    border-radius: 10px;
    -webkit-box-shadow: 7px 20px 18px -24px rgba(0,0,0,0.75);
    -moz-box-shadow: 7px 20px 18px -24px rgba(0,0,0,0.75);
    box-shadow: 7px 20px 18px -24px rgba(0,0,0,0.75);
  }

  .navsubmenu ul {
    list-style-type: none;
    padding-left:0 !important;
    margin-top: 0.7rem;
  }

  .navsubmenu li {
    text-align: center;
    margin: 0 auto;
    padding: 0.6rem;
  }
  
  @media (min-width: 768px) {
    .nav-links {
      flex-direction: row;
    }
  
    .nav-links li {
      margin: 0 0.5rem;
    }
  
    .nav-links a {
      color: #eeeeee;
      text-decoration: none;
    }
  
    .nav-links button {
      border: 1px solid white;
      color: white;
      background: transparent;
    }
    
    .nav-links button:hover,
    .nav-links button:active {
      background: #f8df00;
      color: #292929;
    }
  }