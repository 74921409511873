.header {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 1rem;
    z-index: 5;
    font-family: 'Questrial', sans-serif;
    font-size: 1.1rem;
    display: none;
  }
  
  .main {
    font-family: 'Questrial', sans-serif;
  }
  
  @media (min-width: 768px) {
    .header {
      justify-content: space-between;
    }
  }