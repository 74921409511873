iframe {
  margin-bottom: 1rem;
  padding: 1rem;
}

a {
  text-decoration: none;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.btn-grad {
  background-image: linear-gradient(to right, #24C6DC 0%, #514A9D 51%, #24C6DC 100%);
  margin: 2rem 0 2rem 0;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 4px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.btn-grad:hover { 
  background-position: right center; 
}

.btn-grad-revert {
  background-image: linear-gradient(to right, #60dcec 0%, #ffffff 51%, #60dcec 100%);
  margin: 2rem 0 2rem 0;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #3c4991;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.btn-grad-revert:hover { 
  background-position: right center; 
}

.linkNoStyle {
  text-decoration: none; 
  color: inherit;
}

.btnNoStyle {
  border: none;
  font-family: 'Questrial', sans-serif;
}

.shadowLine {
  width: 100%;
  height: 1px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.75);
  background-color: #fff;
}

.container {
  position: relative;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* FOOTER */

.footer {
  background-color: #070818;
  padding: 4rem 4rem 0.2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: rgb(196, 231, 245) !important;
  bottom: 0;
  position: relative;
}

.footerBlock {
	display: flex;
	flex:1;
	display: flex;
  flex-direction: column;
  z-index: 1;

}

.footerLogo {
  width: 4rem;
}

.footerTitle {
  color: rgb(196, 231, 245);
  margin-left: 3rem;
  font-size: 1.3rem;
}

.footerBlockTitle {
  color: #62c5ff;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.footerLine1 {
  display: flex;
  flex-direction: row;
}

.footerLine2 {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.footerLine3 {
	display: flex;
	justify-content: flex-end;
	color: #62c5ff;
}

.footerMediaLine {
  margin-bottom: 0.75rem;
}

.footerMediaLine a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  color: #c4e7f5;
  text-decoration: none;
}

.footerMediaLine img {
  width: 2rem;
  margin-right: 1rem;
}

.footerBlock1 a {
  color: #c4e7f5;
  text-decoration: none;
}

.centercontent {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(min-width: 0px) and (max-width: 768px) {

  .footerLine1 {
    align-items: center;
  }

  .footerLine2 {
    display: flex;
    flex-direction: column;
  }

};