.helpSysMain {
    width: 100%;
}

.helpPageTop {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.helpPageTopTitle {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
}

.helpPageTopTitle p {
    font-family: 'Questrial', serif;
    font-size: 2rem;
    color: #3c4991;
}

.helpPageTopTitle img {
    margin: 1rem 0;
}

.dottedLine {
    display: none;
}

.helpSystems {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
}

.helpSystems a {
    font-family: 'Questrial', serif;
    font-size: 1.3rem;
    color: #3c4991;
    text-decoration: none;
}

.helpSystems a p {
    margin: 2rem 0;
}

.systemIconLarge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5rem auto;
}

.systemIconLarge img {
    width: 60%;
    transition: 0.2s ease-in-out;
}

.systemIconLarge img:hover {
    transform: scale(1.1);
}

.FAQCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width: 768px) {
    .FAQCards {
        flex-direction: row;
    }

    .helpSystems {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .helpPageTopTitle {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .helpPageTopTitle img {
        margin: 0 2rem;
        max-width: 2rem;
    }

    .dottedLine {
        display: inline;
        margin-top: 1rem;
        border-bottom: 0.3px solid #26337a;
        width: 80vh;
    }

    .systemIconLarge img {
        width: 50%;
    }
}