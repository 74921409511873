@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: white;
  margin-top: 5rem;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
iframe {
  margin-bottom: 1rem;
  padding: 1rem;
}

a {
  text-decoration: none;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.btn-grad {
  background-image: linear-gradient(to right, #24C6DC 0%, #514A9D 51%, #24C6DC 100%);
  margin: 2rem 0 2rem 0;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 4px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.btn-grad:hover { 
  background-position: right center; 
}

.btn-grad-revert {
  background-image: linear-gradient(to right, #60dcec 0%, #ffffff 51%, #60dcec 100%);
  margin: 2rem 0 2rem 0;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #3c4991;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.btn-grad-revert:hover { 
  background-position: right center; 
}

.linkNoStyle {
  text-decoration: none; 
  color: inherit;
}

.btnNoStyle {
  border: none;
  font-family: 'Questrial', sans-serif;
}

.shadowLine {
  width: 100%;
  height: 1px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.75);
  background-color: #fff;
}

.container {
  position: relative;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* FOOTER */

.footer {
  background-color: #070818;
  padding: 4rem 4rem 0.2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: rgb(196, 231, 245) !important;
  bottom: 0;
  position: relative;
}

.footerBlock {
	display: flex;
	flex:1 1;
	display: flex;
  flex-direction: column;
  z-index: 1;

}

.footerLogo {
  width: 4rem;
}

.footerTitle {
  color: rgb(196, 231, 245);
  margin-left: 3rem;
  font-size: 1.3rem;
}

.footerBlockTitle {
  color: #62c5ff;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.footerLine1 {
  display: flex;
  flex-direction: row;
}

.footerLine2 {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.footerLine3 {
	display: flex;
	justify-content: flex-end;
	color: #62c5ff;
}

.footerMediaLine {
  margin-bottom: 0.75rem;
}

.footerMediaLine a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  color: #c4e7f5;
  text-decoration: none;
}

.footerMediaLine img {
  width: 2rem;
  margin-right: 1rem;
}

.footerBlock1 a {
  color: #c4e7f5;
  text-decoration: none;
}

.centercontent {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(min-width: 0px) and (max-width: 768px) {

  .footerLine1 {
    align-items: center;
  }

  .footerLine2 {
    display: flex;
    flex-direction: column;
  }

};
.main-navigation__menu-btn {
  width: 3rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  margin-right: 2rem !important;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 100% !important;
  height: 2px !important;
  margin-bottom: 7px !important;
  background: white;
}

.main-navigation__title {
  color: #3c4991;
}

.main-navigation__title a {
  text-decoration: none;
  color: #3c4991;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

.cmsLogo {
  height: 2rem;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
.header {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 1rem;
    z-index: 5;
    font-family: 'Questrial', sans-serif;
    font-size: 1.1rem;
    display: none;
  }
  
  .main {
    font-family: 'Questrial', sans-serif;
  }
  
  @media (min-width: 768px) {
    .header {
      justify-content: space-between;
    }
  }
  .nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .nav-links li {
    margin: 1rem;
  }
  
  .nav-links a {
    border: 1px solid transparent;
    color: #eeeeee;
    text-decoration: none;
    padding: 0.5rem;
  }
  
  .nav-links a:hover,
  .nav-links a:active,
  .nav-links a.active {
    color: #eeeeee;
  }
  
  .nav-links button {
    cursor: pointer;
    border: 1px solid #eeeeee;
    color: #eeeeee;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:focus {
    outline: none;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #292929;
    color: #eeeeee;
  }

  .gradient-link {
    background-image: linear-gradient(to right, #24C6DC 0%, #514A9D 51%, #24C6DC 100%);
    margin: 2rem 0 2rem 0;
    padding: 10px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: 0 0 1px #eee;
    border-radius: 10px;
    display: block;
  }

  .gradient-link a.active{
    color: white !important;
  }

  .gradient-link:hover { 
      background-position: right center; 
  }

  .nav-submenu {
    display: block;
  }
  
  @media (min-width: 768px) {
    .nav-links {
      flex-direction: row;
    }
  
    .nav-links li {
      margin: 0 0.5rem;
    }
  
    .nav-links a {
      color: #eeeeee;
      text-decoration: none;
      margin: 0 3rem;
      text-align: center;
    }

    .navsubmenuitem a {
      text-align: center;
      margin: 0.5rem !important;
    }
    
    .nav-links button {
      border: 1px solid white;
      color: white;
      background: transparent;
    }
    
    .nav-links button:hover,
    .nav-links button:active {
      background: #f8df00;
      color: #292929;
    }
  }
  .NavLinks_nav-links__3sp4p {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .NavLinks_nav-links__3sp4p li {
    margin: 1rem;
  }
  
  .NavLinks_nav-links__3sp4p a {
    border: 1px solid transparent;
    color: #eeeeee;
    text-decoration: none;
    padding: 0 3rem;
    text-align: center;
  }

  
  .NavLinks_navsubmenuitem__39Zqg a {
    text-align: center;
    margin: 0.5rem !important;
  }

  .NavLinks_navsubmenuitem__39Zqg:hover {
    background-color:rgb(46, 58, 83);
  }

  .NavLinks_navsubmenuitem__39Zqg:hover:last-child {
    background-color:rgb(46, 58, 83);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .NavLinks_nav-links__3sp4p button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }
  
  .NavLinks_nav-links__3sp4p a:hover,
  .NavLinks_nav-links__3sp4p a:active,
  .NavLinks_nav-links__3sp4p a.NavLinks_active__rcl_f {
    color: #eeeeee;
  }
  
  .NavLinks_nav-links__3sp4p button {
    cursor: pointer;
    border: 1px solid #eeeeee;
    color: #eeeeee;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .NavLinks_nav-links__3sp4p button:focus {
    outline: none;
  }
  
  .NavLinks_nav-links__3sp4p button:hover,
  .NavLinks_nav-links__3sp4p button:active {
    background: #292929;
    color: #eeeeee;
  }

  .NavLinks_gradient-link__1mi30 {
    background-image: linear-gradient(to right, #24C6DC 0%, #514A9D 51%, #24C6DC 100%);
    margin: 2rem 0 2rem 0;
    padding: 10px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: 0 0 2px #eee;
    border-radius: 10px;
    display: block;
  }

  .NavLinks_gradient-link__1mi30 a.NavLinks_active__rcl_f{
    color: white !important;
  }

  .NavLinks_gradient-link__1mi30:hover { 
      background-position: right center; 
  }

  .NavLinks_navsubmenu__c8JHD {
    display: block;
    position: absolute;
    background: #5f699c;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    border-radius: 10px;
    box-shadow: 7px 20px 18px -24px rgba(0,0,0,0.75);
  }

  .NavLinks_navsubmenu__c8JHD ul {
    list-style-type: none;
    padding-left:0 !important;
    margin-top: 0.7rem;
  }

  .NavLinks_navsubmenu__c8JHD li {
    text-align: center;
    margin: 0 auto;
    padding: 0.6rem;
  }
  
  @media (min-width: 768px) {
    .NavLinks_nav-links__3sp4p {
      flex-direction: row;
    }
  
    .NavLinks_nav-links__3sp4p li {
      margin: 0 0.5rem;
    }
  
    .NavLinks_nav-links__3sp4p a {
      color: #eeeeee;
      text-decoration: none;
    }
  
    .NavLinks_nav-links__3sp4p button {
      border: 1px solid white;
      color: white;
      background: transparent;
    }
    
    .NavLinks_nav-links__3sp4p button:hover,
    .NavLinks_nav-links__3sp4p button:active {
      background: #f8df00;
      color: #292929;
    }
  }
.side-drawer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background: rgba(17, 16, 16, 0.8);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }
  
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
  }
  
.HelpMain_helpSysMain__2t17p {
    width: 100%;
}

.HelpMain_helpPageTop__34Djz {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HelpMain_helpPageTopTitle__3ol0O {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
}

.HelpMain_helpPageTopTitle__3ol0O p {
    font-family: 'Questrial', serif;
    font-size: 2rem;
    color: #3c4991;
}

.HelpMain_helpPageTopTitle__3ol0O img {
    margin: 1rem 0;
}

.HelpMain_dottedLine__1uNBR {
    display: none;
}

.HelpMain_helpSystems__2Znpr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
}

.HelpMain_helpSystems__2Znpr a {
    font-family: 'Questrial', serif;
    font-size: 1.3rem;
    color: #3c4991;
    text-decoration: none;
}

.HelpMain_helpSystems__2Znpr a p {
    margin: 2rem 0;
}

.HelpMain_systemIconLarge__Pgcx4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5rem auto;
}

.HelpMain_systemIconLarge__Pgcx4 img {
    width: 60%;
    transition: 0.2s ease-in-out;
}

.HelpMain_systemIconLarge__Pgcx4 img:hover {
    transform: scale(1.1);
}

.HelpMain_FAQCards__3rWxG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width: 768px) {
    .HelpMain_FAQCards__3rWxG {
        flex-direction: row;
    }

    .HelpMain_helpSystems__2Znpr {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .HelpMain_helpPageTopTitle__3ol0O {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .HelpMain_helpPageTopTitle__3ol0O img {
        margin: 0 2rem;
        max-width: 2rem;
    }

    .HelpMain_dottedLine__1uNBR {
        display: inline;
        margin-top: 1rem;
        border-bottom: 0.3px solid #26337a;
        width: 80vh;
    }

    .HelpMain_systemIconLarge__Pgcx4 img {
        width: 50%;
    }
}
.SystemHelp_stepperScroll__2nntV {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.SystemHelp_starColour__1h3_V {
    color: #8F0C0C;
}

.SystemHelp_helpMenuBox__1yLA1 {
    margin: 2rem auto;
}

.SystemHelp_helpMenuTab__PIVAt {
    padding: 1rem;
    border-radius: 10px;
    background-image: linear-gradient(90deg, rgba(60,73,145,1) 0%, rgb(125, 139, 221) 51%, rgba(6,131,156,1) 100%);
    color: #fafafa;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: 150% auto;
    margin: 1rem 0;
}

.SystemHelp_hh1__36BQc {
    visibility: hidden;
}

.SystemHelp_helpMenuTabIcon__2X5hT {
    margin-right: 0.7rem;
    display: flex;
    align-items: cenetr;
}

.SystemHelp_helpMenuTab__PIVAt:hover {
    background-position: right center; 
}

.SystemHelp_helpMenuTabList__3NFRo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SystemHelp_instructionWithPointers__3t3sV {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
}

.SystemHelp_instructionWithTitle__3Jxce h2 {
    font-family: 'Questrial', serif;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    text-align: center;
    margin: 2rem 0;
}

.SystemHelp_instructionScrollBox__1le_j {
    padding: 2.5rem;
    max-height: 50rem;
    overflow-y: scroll;
    background-color: #f5f7ff;
    border-radius: 20px;
}

.SystemHelp_sliderWithVideo__3Ionk {
    display: flex;
    flex-direction: column;
}

.SystemHelp_sliderWithVidTitle__1Swkq {
    font-family: 'Questrial', serif;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3c4991;
    font-size: 2rem;
    margin: 4rem 0;
}

.SystemHelp_instructionScrollList__1HDfs {
    min-width: 100%;
}

.SystemHelp_instructionSectionTitle__1EBQ0 {
    font-size: 1.5rem;
}

.SystemHelp_instructionSectionTxtArea__1lSNe {
    margin: 3rem 0 3rem 0;
}

.SystemHelp_instructionSectionTxtArea__1lSNe h3 {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
}

.SystemHelp_instructionSectionTxt__2bN3V {
    margin: 1.5rem 0;
}

.SystemHelp_instructionSectionTxt__2bN3V, .SystemHelp_instructionSectionTxtArea__1lSNe img {
    margin: 0.5rem 0 0.75rem 0;
    max-width: 100%;
}

.SystemHelp_alignHelpImage__2_TxZ {
    display: flex;
    flex-direction: column;
}

.SystemHelp_tutorialVideo__2A6fu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SystemHelp_videoMockUp__3wCok {
    width: 90%;
    height: 15rem;
    border-radius: 20px;
    background-image: linear-gradient(-20deg, #5997c0 50%, #655699 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2rem auto;
}

.SystemHelp_videoMockUp__3wCok span {
    color: #fafafa;
}

@media (min-width: 768px) {
    .SystemHelp_helpMenuTabList__3NFRo {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 auto 2rem auto;
        padding-top: 3rem;
        width: 80%;
    }

    .SystemHelp_helpMenuTab__PIVAt {
        min-width: 12rem;
        margin: 0;
    }

    .SystemHelp_videoMockUp__3wCok {
        width: 25rem;
        height: 15rem;
        margin: 0 0 2rem 0;
    }

    .SystemHelp_helpImgSm__2iKFq {
        width: 20%;
    }

    .SystemHelp_helpImgMd__1BxoG {
        width: 50%;
    }

    .SystemHelp_stepperScroll__2nntV {
        overflow: hidden;
    }

    .SystemHelp_instructionWithPointers__3t3sV {
        flex-direction: row;
        align-items: flex-start;
        padding: 1rem;
    }

    .SystemHelp_instructionWithTitle__3Jxce h2 {
        text-align: left;
        margin: 0 0 2rem 0;
    }

    .SystemHelp_instructionScrollList__1HDfs {
        min-width: 15rem;
        margin-right: 3rem;
    }
}
